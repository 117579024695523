import React from 'react';
import styles from './info.module.css';
import {useWindowSize} from '../../../../../hooks/use-window-size';

export const Info = props => {
  // props
  const {descriptionTitle, descriptionTextArray, descriptionTitleMobile} = props.event;
  // hooks
  const {width: windowSizeWidth} = useWindowSize();
  // variables
  const isMobile = windowSizeWidth <= 625;

  const bulletPointAlignment = () => {
    if (isMobile) {
      return {left: '6px'};
    } else {
      return {right: '3px'};
    }
  };

  // render
  return (
    <section className={styles.container}>
      <h3 className={styles.title}>{isMobile ? descriptionTitleMobile : descriptionTitle}</h3>

      {descriptionTextArray.map((description, index) => {

        // bullet points
        const mapBulletPointsList = bulletPoints => (
          <>
            {bulletPoints.map((bp, index) => {
              return (
                <p
                  key={index}
                  className={styles.bulletPoint}
                  style={isMobile ? {paddingLeft: '1.75rem'} : {paddingLeft: '2.5rem'}}
                >
                  <i
                    class="circle icon mini"
                    style={{position: 'relative', bottom: '0.225rem', ...bulletPointAlignment()}}
                  ></i>
                  {bp}
                </p>
              );
            })}
          </>
        );

        // main render
        return (
          <>
            <p key={index} className={styles.text}>
              {description.text}
            </p>
            {!!description.bulletPoints && (
              <div style={{paddingBottom: '2rem', alignSelf: 'flex-start'}}>
                {mapBulletPointsList(description.bulletPoints)}
              </div>
            )}
          </>
        );
      })}
    </section>
  );
};
