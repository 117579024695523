import Event from '../../components/views/event/event'
import { events as eventsContent } from '../../helpers/content';
import Layout from '../../components/layout';
import React from 'react';
import SEO from '../../components/seo';

const IndexPage = () => {
    
    const genericEvent = eventsContent.generic

    return (
    <Layout>
        <SEO title="Events" />
        <Event event={genericEvent} />
    </Layout>
    )
}

export default IndexPage;