import React from 'react'
import styles from './intro.module.css'

export const Intro = (props) => {
    const {introImagePath, introImageText} = props.event
    return (
        <section className={styles.container} style={{backgroundImage: `url("${introImagePath}")`}}>
            <div className={styles.content}>
                <h2 className={styles.text}>{introImageText}</h2>
            </div>
        </section>
    )
}

