import React from 'react';
import {Intro, Info, Contact} from './sections'
import {Footer, NavigationBar} from '../../sections';

const Event = (props) => {

    const {event} = props
    console.log('event information', event)
    return (
        <>
            <NavigationBar />
            <Intro event={event} />
            <Info event={event} />
            <Contact event={event} />
            <Footer />
        </>
    );
}

export default Event;